<template>
    <div v-if="project">
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Name:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <p class="text-body-1" style="margin-top: 5px">
                    {{ project.name }}
                </p>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Client:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <template>
                    <v-card flat>
                        <div class="d-flex my-1" style="cursor: pointer">
                            <v-avatar
                                size="34"
                                color="grey lighten-4"
                                class="mr-1 ml-1"
                            >
                                <v-img v-if="clientImage" :src="clientImage" />
                                <span v-else class="text-h6">
                                    {{
                                        getClientName(project.client)
                                            .split(' ')
                                            .map((n, i, a) =>
                                                i === 0 || i + 1 === a.length
                                                    ? n[0]
                                                    : null
                                            )
                                            .join('')
                                            .toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            <div
                                class="d-flex flex-column justify-space-around me-4"
                            >
                                <span class="font-weight-bold text-singleline">
                                    {{ getClientName(project.client) }}
                                </span>
                            </div>
                        </div>
                    </v-card>
                </template>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Account Manager:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <template>
                    <v-card flat>
                        <div class="d-flex my-1" style="cursor: pointer">
                            <v-avatar
                                size="34"
                                color="grey lighten-4"
                                class="mr-1 ml-1"
                            >
                                <v-img
                                    v-if="accountManagerImage"
                                    :src="accountManagerImage"
                                />
                                <span v-else class="text-h6">
                                    {{
                                        getUserName(project.accountManager)
                                            .split(' ')
                                            .map((n, i, a) =>
                                                i === 0 || i + 1 === a.length
                                                    ? n[0]
                                                    : null
                                            )
                                            .join('')
                                            .toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            <div
                                class="d-flex flex-column justify-space-around me-4"
                            >
                                <span class="font-weight-bold text-singleline">
                                    {{ getUserName(project.accountManager) }}
                                </span>
                            </div>
                        </div>
                    </v-card>
                </template>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Project Manager:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <template>
                    <v-card flat>
                        <div class="d-flex my-1" style="cursor: pointer">
                            <v-avatar
                                size="34"
                                color="grey lighten-4"
                                class="mr-1 ml-1"
                            >
                                <v-img
                                    v-if="projectManagerImage"
                                    :src="projectManagerImage"
                                />
                                <span v-else class="text-h6">
                                    {{
                                        getUserName(project.projectManager)
                                            .split(' ')
                                            .map((n, i, a) =>
                                                i === 0 || i + 1 === a.length
                                                    ? n[0]
                                                    : null
                                            )
                                            .join('')
                                            .toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            <div
                                class="d-flex flex-column justify-space-around me-4"
                            >
                                <span class="font-weight-bold text-singleline">
                                    {{ getUserName(project.projectManager) }}
                                </span>
                            </div>
                        </div>
                    </v-card>
                </template>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Collaborators:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <template>
                    <v-card flat>
                        <div
                            class="d-flex my-1"
                            style="cursor: pointer"
                            v-for="(collaborator, index) of collaborators"
                            :key="index"
                        >
                            <v-avatar
                                size="34"
                                color="grey lighten-4"
                                class="mr-1 ml-1"
                            >
                                <v-img
                                    v-if="collaborator.picture"
                                    :src="collaborator.picture"
                                />
                                <span v-else class="text-h6">
                                    {{
                                        collaborator.name
                                            .split(' ')
                                            .map((n, i, a) =>
                                                i === 0 || i + 1 === a.length
                                                    ? n[0]
                                                    : null
                                            )
                                            .join('')
                                            .toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            <div
                                class="d-flex flex-column justify-space-around me-4"
                            >
                                <span class="font-weight-bold text-singleline">
                                    {{ collaborator.name }}
                                </span>
                            </div>
                        </div>
                    </v-card>
                </template>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Price:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <p class="text-body-1" style="margin-top: 5px">
                    {{ formatCurrency(project.price) }}
                </p>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Amount Billed:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <p class="text-body-1" style="margin-top: 5px">
                    {{ formatCurrency(project.totalInvoiced) }}
                </p>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Currency:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <p class="text-body-1" style="margin-top: 5px">
                    {{ project.currency }}
                </p>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-2 text-h6 text-bold">Location:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <p class="text-body-1" style="margin-top: 5px">
                    {{ project.city }} - {{ project.state }} -
                    {{ project.country }}
                </p>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="localQuote">
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-4 text-h6 text-bold">Follow up:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <v-autocomplete
                    v-model="localQuote"
                    :items="selectedQuotes"
                    flat
                    label="Quotes"
                    item-text="name"
                    return-object
                    class="mt-n3"
                    @change="changeQuoteToFollowUp"
                >
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="project.archive">
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-4 text-h6 text-bold">Exports Closed:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <span
                    class="py-0 mt-1"
                    style="font-size: 14px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                    @click="openFile(project.exports)"
                    >{{ project.exports }}</span
                >
            </v-col>
        </v-row>
        <v-row no-gutters v-if="project.archive">
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-4 text-h6 text-bold">
                    Portfolio Reconciled With The Client:
                </p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <span
                    class="py-0 mt-1"
                    style="font-size: 14px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                    @click="openFile(project.clientPortfolio)"
                    >{{ project.clientPortfolio }}</span
                >
            </v-col>
        </v-row>
        <v-row no-gutters v-if="project.archive">
            <v-col cols="4" sm="3" md="3" lg="2" xl="2" class="d-flex">
                <p class="mr-4 text-h6 text-bold">Project Closed In SAP:</p>
            </v-col>
            <v-col
                cols="8"
                sm="9"
                md="9"
                lg="10"
                xl="10"
                class="d-flex  flex-wrap"
            >
                <span
                    class="py-0 mt-1"
                    style="font-size: 14px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                    @click="openFile(project.closingInSAP)"
                    >{{ project.closingInSAP }}</span
                >
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { storage } from '@/services/firebase'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { mapMutations } from 'vuex'

export default {
    name: 'ProjectDetails',
    props: {
        project: Object,
        quotes: Array,
        users: Array,
        clients: Array,
    },
    data: () => ({
        selectedQuotes: [],
        localQuote: undefined,
        errorMsg: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        clientImage: '',
        accountManagerImage: '',
        projectManagerImage: '',
        collaborators: [],
    }),
    inject: {
        replaceQuoteToFollowUp: {
            from: 'replaceQuoteToFollowUp',
            default() {
                return null
            },
        },
    },
    watch: {
        quotes() {
            this.setQuoteInfo()
        },
    },
    async mounted() {
        // load client image
        const client = this.clients.find(c => c.id === this.project.client)
        this.clientImage = ''
        if (client.picture) {
            this.clientImage = await this.loadImage(
                client.picture,
                'clients_pictures'
            )
        }

        // load account manager image
        const aManager = this.users.find(
            c => c.id === this.project.accountManager
        )
        this.accountManagerImage = ''
        if (aManager.picture) {
            this.accountManagerImage = await this.loadImage(
                aManager.picture,
                'users_pictures'
            )
        }

        // load project manager image
        const pManager = this.users.find(
            c => c.id === this.project.projectManager
        )
        this.projectManagerImage = ''
        if (pManager.picture) {
            this.projectManagerImage = await this.loadImage(
                pManager.picture,
                'users_pictures'
            )
        }

        // load collaborators images
        for (let collaborator of this.project.collaborators) {
            const user = this.users.find(c => c.id === collaborator)
            if (user) {
                collaborator = { name: user.name }
                if (user.picture) {
                    collaborator.picture = await this.loadImage(
                        user.picture,
                        'users_pictures'
                    )
                }
                this.collaborators.push(collaborator)
            }
        }

        const {
            data: { user },
        } = await API.getMyInfo()

        if (this.quotes) {
            this.setQuoteInfo(this.quotes)
        } else {
            if (user.permissions.includes('readProjectQuotes')) {
                const quotes = await API.getProjectQuotes(this.project.id)
                this.setQuoteInfo(quotes)
            }
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async openFile(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/closing`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatCurrency(price) {
            return new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
            }).format(price ? parseInt(price) : 0)
        },
        getUserName(id) {
            if (this.users.length > 0) {
                const userReference = this.users.find(user => user.id == id)
                if (userReference) {
                    return userReference.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                const clientReference = this.clients.find(
                    client => client.id == id
                )
                if (clientReference) {
                    return clientReference.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getQuoteName(id) {
            const quoteId = id.split('/')[0]
            if (this.quotes.length > 0) {
                return this.quotes.find(quote => quote.id == quoteId).name
            } else {
                return ''
            }
        },
        async loadImage(picture, folder) {
            try {
                this.loading = true
                this.errorMsg = undefined
                const path = `${this.companyId}/${folder}`
                const storageRef = storage().ref(path)
                const profilePicRef = storageRef.child(picture)
                var url = ''
                await profilePicRef
                    .getDownloadURL()
                    .then(res => {
                        url = res
                    })
                    .catch(() => {
                        url = ''
                    })
                return url
            } catch (error) {
                this.errorMsg = error.message
                return ''
            } finally {
                this.loading = false
            }
        },
        changeQuoteToFollowUp(val) {
            this.replaceQuoteToFollowUp(val)
        },
        setQuoteInfo(quotes) {
            this.selectedQuotes = this.project.quotes.map(quote => {
                return quotes.find(q => q.id === quote.split('/')[0])
            })
            if (
                !this.project.quoteToFollowUp &&
                this.project.quotes.length > 0
            ) {
                this.project.quoteToFollowUp = this.project.quotes[0].split(
                    '/'
                )[0]
            }
            this.localQuote = quotes.find(quote => {
                return quote.id === this.project.quoteToFollowUp
            })
            this.replaceQuoteToFollowUp(this.localQuote)
        },
    },
}
</script>

<style></style>
